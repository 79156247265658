// Specifikace nepodporovaných browserů
export const browserSupoort = (userAgent: string) => {
    const UA = userAgent.toLowerCase() ?? '';
    // const isIE = UA && /; msie|trident/i.test(UA);
    const isEdge = UA && /edg/i.test(UA);
    const isAndroid = UA && UA.indexOf('android') > 0;
    const isIOS = UA && /iphone|ipad|ipod|ios/i.test(UA);
    const isChrome = UA && /chrome|crios/i.test(UA) && !/opr|opera|chromium|edg|ucbrowser|googlebot|presto/i.test(UA);
    // const isGoogleBot = UA && /googlebot/i.test(UA);
    const isChromium = UA && /chromium/i.test(UA);
    // const isUcBrowser = UA && /ucbrowser/i.test(UA);
    const isSafari = UA && /safari/i.test(UA) && !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox|presto/i.test(UA);
    const isFirefox = UA && /firefox|fxios/i.test(UA) && !/seamonkey/i.test(UA);
    // const isOpera = UA && /opr|opera/i.test(UA);
    const isMobile = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    // const isSamsung = UA && /samsungbrowser/i.test(UA);
    const isIPad = UA && /ipad/.test(UA);
    const isIPhone = UA && /iphone/.test(UA);
    const isIPod = UA && /ipod/.test(UA);

    return isEdge || isAndroid || isIOS || isIPad || isIPhone || isIPod || isChrome || isChromium || isSafari || isFirefox || isMobile;
};

export const statusCode = [401, 403, 503];

export const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
