import {Divider, Icon, Paper, PaperProps, Typography} from '@mui/material';
import {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {useMatches} from '../../utils/CommonUtils';
import {Div} from '../styledComponents/Div';

type PaperBlockProps = PaperProps & {
    readonly titleBlock: string | JSX.Element | undefined;
    readonly description?: string | JSX.Element;
    readonly titleBlockIcon?: JSX.Element | string;
    readonly titleRightBlock?: JSX.Element | string;
    readonly centerTitle?: boolean;
    readonly paper?: boolean;
};

export const PaperBlock: FC<PaperBlockProps> = ({children, titleBlock, titleBlockIcon, description, centerTitle, titleRightBlock, paper = true}) => {
    const matches = useMatches();

    const Component = paper ? Paper : Div;

    return (
        <Component sx={{backgroundColor: 'background.paper', position: 'relative'}}>
            <Div
                sx={{
                    width: '100%',
                    position: 'absolute',
                    height: 4,
                    top: 0,
                    left: 0,
                    borderTopLeftRadius: 2,
                    borderTopRightRadius: 2,
                    backgroundColor: 'primary.main',
                }}
            />

            <Div
                sx={{
                    ...(centerTitle
                        ? {
                              display: 'contents',
                              textAlign: 'center',
                          }
                        : {
                              display: 'flex',
                              alignItems: 'center',
                              height: 60,
                          }),
                }}
            >
                {titleBlockIcon && (
                    <Icon
                        sx={{
                            marginLeft: 2,
                            display: 'flex',
                        }}
                    >
                        {titleBlockIcon}
                    </Icon>
                )}

                {titleBlock && (
                    <Typography
                        sx={{
                            marginLeft: 2,
                        }}
                        variant={matches ? 'h6' : 'h5'}
                    >
                        {typeof titleBlock === 'string' ? <FormattedMessage id={titleBlock} /> : titleBlock}
                    </Typography>
                )}
                {titleRightBlock && (
                    <Div
                        sx={{
                            marginLeft: 'auto',
                        }}
                    >
                        {titleRightBlock}
                    </Div>
                )}

                {description && (
                    <Typography variant="subtitle1">{typeof description === 'string' ? <FormattedMessage id={description} /> : description}</Typography>
                )}
            </Div>
            <Divider />
            <Div sx={(theme) => ({margin: theme.spacing(2, 2, 2, 2), paddingBottom: 2})}>{children}</Div>
        </Component>
    );
};
