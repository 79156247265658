import {useMediaQuery} from '@mui/material';
import {Maybe} from 'graphql/jsutils/Maybe';
import {isEqual, isFunction, omitBy} from 'lodash';
import {ReactNode} from 'react';
import sanitizeHtml from 'sanitize-html';

export const EAN_PREFIX = '859182400';
export const EIC_PREFIX = '27ZG900';

export const nullAsEmpty = (s: string | null | undefined | Maybe<string>): string => (typeof s === 'string' ? s : '');
export const isNullOrUndefined = <T extends Record<string, unknown> | string | null | ReactNode>(val: T) => val == null;

export const isNullOrUndefinedOrEmpty = <T extends Record<string, unknown> | string | null | ReactNode>(val: T) =>
    isNullOrUndefined(val) || val === '' || val === 'undefined';

/**
 * If the value is not null, undefined, or an empty string, return true.
 * @param {T} val - T - The value to check
 */
export const isNotNullOrUndefinedOrEmpty = <T extends string | undefined | null>(val: T) => !isNullOrUndefinedOrEmpty(val);

/**
 * It returns true if the two objects are equal, except for functions
 * @param {T} prevProps - The previous props that were passed to the component.
 * @param {T} nextProps - The next props that will be received by the component.
 * @returns A function that takes two arguments, prevProps and nextProps, and returns a boolean.
 */
export const areEqual = <T extends Record<string, unknown>>(prevProps: T, nextProps: T): boolean => {
    const [prev, next] = [prevProps, nextProps].map((props) => omitBy(props, isFunction));
    return isEqual(prev, next);
};

/**
 * It returns true if the screen is less than 500px wide
 * @param [maxWidth=500px] - The maximum width of the screen to match.
 */
export const useMatches = (maxWidth = '500px') => useMediaQuery(`(max-width:${maxWidth})`);

/**
 * It takes a string of HTML and returns a string of HTML that's been sanitized
 * @param {string} html - The HTML string to sanitize.
 */
export const sanitizeHTML = (html: string) => sanitizeHtml(html);

/**
 * Prefixes the EAN code with the EAN prefix.
 *
 * @param ean - EAN code
 * @returns ean code with prefix `859182400${ean}`
 */
export const addEanPrefix = (ean: string) => `${EAN_PREFIX}${ean}`;

/**
 * Prefixes the EIC code with EIC prefix.
 *
 * @param eic - EIC code
 * @returns eic code with prefix `27ZG900${eic}`
 */
export const addEicPrefix = (eic: string) => `${EIC_PREFIX}${eic}`;
